<template>
  <v-card class="px-2 pb-5" v-if="actualImageUrl !== undefined">
    <v-card-title>{{ title }}</v-card-title>
    <v-col cols="12">
      <v-row class="mt-4" align="center" justify="space-around">
        <h3 class="ml-8">
          {{ actualImageLabel }}
        </h3>
        <v-img
            :src="actualImageUrl.concat('?timestamp=').concat(Date.now())"
            :max-height="maxPreviewHeight"
            max-width="20vw"
            contain
            alt="Creditor logo"
        ></v-img>
      </v-row>
    </v-col>

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-file-input
            v-model="selectedFile"
            @change="preview()"
            @click:clear="clear()"
            :placeholder="$t('FILE_INPUT_PNG')"
            accept=".png"
            ref="fileSelector"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row class="mt-2 px-5">
      <v-alert type="info" dense outlined>
        {{ $t('DIMENSIONS_INFO', {minimumImageWidth, maximumImageWidth, minimumImageHeight, maximumImageHeight }) }}
      </v-alert>
    </v-row>

    <template v-if="selectedFilePreview">
      <v-row>
        <v-col cols="12" md="8">
          <v-row align="center" justify="space-around">
            <h3 class="ml-8">
              {{ $t('PREVIEW') }}
            </h3>
            <v-img
                ref="previewImage"
                :src="selectedFilePreview"
                :max-height="maxPreviewHeight"
                max-width="20vw"
                contain
                alt="Creditor logo"
            ></v-img>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
              color="primary"
              @click="uploadImage">
            {{ $t('SAVE')}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      selectedFile: undefined,
      selectedFilePreview: undefined,
      uploadedImageHeight: undefined,
      uploadedImageWidth: undefined
    };
  },
  props: {
    maximumImageHeight: {
      type: Number,
      required: true
    },
    minimumImageHeight: {
      type: Number,
      required: true
    },
    maximumImageWidth: {
      type: Number,
      required: true
    },
    minimumImageWidth: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    actualImageLabel: {
      type: String,
      required: true
    },
    eventToEmit: {
      type: String,
      required: true
    },
    actualImageUrl: {
      required: true
    }
  },
  methods: {
    preview () {
      this.selectedFilePreview = window.URL.createObjectURL(event?.target.files[0]);
    },
    clear () {
      this.selectedFilePreview = undefined;
    },
    uploadImage () {
      this.uploadedLogoHeight = this.$refs.previewImage?.image.naturalHeight;
      this.uploadedLogoWidth = this.$refs.previewImage?.image.naturalWidth;
      if (this.imageDimensionsAreValid()) {
        this.$emit(this.eventToEmit, this.selectedFile);
      } else {
        this.displayErrorSnackbar(this.$t('DIMENSIONS_ERROR'));
      }
    },
    imageDimensionsAreValid () {
      return this.uploadedLogoHeight >= this.minimumImageHeight && this.uploadedLogoHeight <= this.maximumImageHeight &&
          this.uploadedLogoWidth >= this.minimumImageWidth && this.uploadedLogoWidth <= this.maximumImageWidth;
    }
  },
  computed: {
    maxPreviewHeight () {
      if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) {
        return 56;
      }
      return 64;
    }
  }
};
</script>