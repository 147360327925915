<template>
  <div>
    <v-row>
      <v-col cols="4" md="3">
        <RealmSelector
          @select-realm="filterMetricsOnSelectedRealm"
          :class="{'realm-selector': true, 'realm-selector-not-xs': !$vuetify.breakpoint.xs}"
        ></RealmSelector>
      </v-col>
      <v-col cols="4" md="2">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="$t('START_DATE')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
            :max="currentDate"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" md="2">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="stopDate"
              :label="$t('STOP_DATE')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="stopDate"
            no-title
            scrollable
            :max="currentDate"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="showMetrics" cols="12" md = "5" class="text-right pt-sm-8 pr-10">
        {{ $t('BO_LAST_DATA_DATE', { lastComputationDate }) }}
      </v-col>
    </v-row>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <v-row v-else-if="showMetrics">

      <v-col v-for="section in metricCards" :key="section.title" cols="6" class="pt-0">
        <v-container>
          <h1 class="text-center">{{ $t(section.title) }}</h1>
          <div v-if="isBoSectionPaymentsOrMatching(section.title) && !paymentMetrics" class="text-center pt-16">
            <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
          </div>
          <v-card v-else
            elevation="8"
            class="mx-auto pa-5"
            color="#f1f1f1">
            <v-row dense>
              <v-col cols="6" md="4" v-for="card in section.cards" :key="card.title">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      height="100%"
                      class="text-center"
                      v-bind="attrs"
                      v-on="on">
                      <v-card-title class="justify-center force-break-word">{{ $t(card.title) }}</v-card-title>
                      <v-card-text class="black--text">
                        <strong>{{ card.value }}</strong>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span>{{ $t(card.tooltip) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!-- CHART COMPONENT -->
            <v-row v-if="isBoSectionInvoices(section.title)" dense>
              <v-col cols="12" lg="8">
                <v-card elevation="6">
                  <v-card-title class="justify-center force-break-word">
                  {{ $t('PAYMENT_VS_ISSUE') }}
                  </v-card-title>
                  <PaymentKpiBarChart class="px-xl-12 px-lg-0 px-12"
                      v-bind:kpi-data="getPaymentVsIssueDate">
                  </PaymentKpiBarChart>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isBoSectionInvoices(section.title)" dense>
              <v-col cols="12" lg="8">
                <v-card elevation="6">
                  <v-card-title class="justify-center force-break-word">
                  {{ $t('PAYMENT_VS_DUE') }}
                  </v-card-title>
                  <PaymentKpiLineChart class="px-xl-12 px-lg-0 px-12"
                      v-bind:kpi-data="getPaymentVsDueDate">
                  </PaymentKpiLineChart>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <div v-else class="text-center pt-16">
      <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
    </div>
  </div>
</template>

<script>
import RealmSelector from '@/components/backoffice/RealmSelector';
import PaymentKpiBarChart from '@/components/common/kpi/PaymentKpiBarChart.vue';
import PaymentKpiLineChart from '@/components/common/kpi/PaymentKpiLineChart.vue';
import backofficeService from '@/services/backofficeService';
import paymentKpiMixin from '@/mixins/paymentKpiMixin';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [dateMixin, paymentKpiMixin, numberMixin],
  components: {
    RealmSelector, PaymentKpiBarChart, PaymentKpiLineChart
  },
  beforeMount () {
    this.currentDate = this.getCurrentDate();
    this.startDate = this.getFirstDayOfMonth(this.currentDate);
    this.stopDate = this.getCurrentDate();
  },
  data: () => ({
    clientsCount: undefined,
    currentDate: undefined,
    kpiSalesMetrics: undefined,
    loading: true,
    startDate: undefined,
    stopDate: undefined,
    selectedKpiSalesMetrics: undefined,
    selectedRealm: undefined
  }),
  computed: {
    metricCards () {
      return [
        {
          title: 'BO_SECTION_PAYMENTS',
          cards: [
            {
              title: 'BO_M_PAYMENTS_TOTAL',
              tooltip: 'BO_M_PAYMENTS_TOTAL_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.totalPaymentsCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.totalPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_PIS',
              tooltip: 'BO_M_PAYMENTS_BY_PIS_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.pisPaymentsCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.pisPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_DEBIT',
              tooltip: 'BO_M_PAYMENTS_BY_DEBIT_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.sepAddPaymentsCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.sepAddPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_CREDIT_CARD',
              tooltip: 'BO_M_PAYMENTS_BY_CREDIT_CARD_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.ccPaymentsCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.ccPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_CREDIT_NOTE',
              tooltip: 'BO_M_PAYMENTS_BY_CREDIT_NOTE_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.cnPaymentsCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.cnPaymentsAmount)
            }
          ]
        },
        {
          title: 'BO_SECTION_MATCHING',
          cards: [
            {
              title: 'BO_M_MATCHES_TOTAL',
              tooltip: 'BO_M_MATCHES_TOTAL_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.totalMatchesCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.totalMatchesAmount)
            },
            {
              title: 'BO_M_MATCHES_BY_PIS',
              tooltip: 'BO_M_MATCHES_BY_PIS_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.pisMatchesCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.pisMatchesAmount)
            },
            {
              title: 'BO_M_MATCHES_BY_DEBIT',
              tooltip: 'BO_M_MATCHES_BY_DEBIT_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.sepAddMatchesCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.sepAddMatchesAmount)
            },
            {
              title: 'BO_M_MATCHES_BY_CREDIT_CARD',
              tooltip: 'BO_M_MATCHES_BY_CREDIT_CARD_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.ccMatchesCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.ccMatchesAmount)
            },
            {
              title: 'BO_M_MATCHES_BY_CREDIT_NOTE',
              tooltip: 'BO_M_MATCHES_BY_CREDIT_NOTE_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.cnMatchesCount, this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.cnMatchesAmount)
            }
          ]
        },
        {
          title: 'BO_SECTION_INVOICES',
          cards: [
            {
              title: 'BO_M_INVOICES_ADDED',
              tooltip: 'BO_M_INVOICES_ADDED_TOOLTIP',
              value: this.selectedKpiSalesMetrics.invoiceMetricsAggregatedDto.addedInvoices
            },
            {
              title: 'BO_M_INVOICES_PAID',
              tooltip: 'BO_M_INVOICES_PAID_TOOLTIP',
              value: this.selectedKpiSalesMetrics.invoiceMetricsAggregatedDto.paidInvoices
            }
          ]
        },
        {
          title: 'BO_SECTION_CLIENTS_USERS',
          cards: [
            {
              title: 'BO_M_CLIENTS',
              tooltip: 'BO_M_CLIENTS_TOOLTIP',
              value: this.clientsCount
            },
            {
              title: 'BO_M_DEBTOR_ACCOUNTS',
              tooltip: 'BO_M_DEBTOR_ACCOUNTS_TOOLTIP',
              value: this.selectedKpiSalesMetrics.backofficeMetricsAggregatedDto.debtorAccountsCount
            }
          ]
        }
      ];
    },
    showMetrics () {
      return this.selectedKpiSalesMetrics && !this.selectedKpiSalesMetrics.length && this.selectedKpiSalesMetrics.computationDate;
    },
    paymentMetrics () {
      return this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto && !this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.length && this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.currency;
    },
    isBoSectionInvoices: function () {
      return (title) => title === 'BO_SECTION_INVOICES';
    },
    isBoSectionPaymentsOrMatching: function () {
      return (title) => title === 'BO_SECTION_PAYMENTS' || title === 'BO_SECTION_MATCHING';
    },
    formatAndShowMetric: function () {
      return (count, amount) => this.paymentMetrics ? count + ' / ' + amount.toFixed(2) + this.creditorCurrencySymbol : count + ' / ' + amount;
    },
    lastComputationDate () {
      return this.$options.filters.date(this.selectedKpiSalesMetrics.computationDate);
    },
    getPaymentVsIssueDate () {
      const values = this.selectedKpiSalesMetrics.invoiceMetricsAggregatedDto.issueDateToPaymentDateDaysInterval;
      // paymentKpiMixin function
      return this.paymentVsIssueDate(values);
    },
    getPaymentVsDueDate () {
      const values = this.selectedKpiSalesMetrics.invoiceMetricsAggregatedDto.paymentDateToDueDateDaysInterval;
      // paymentKpiMixin function
      return this.paymentVsDueDate(values);
    },
    creditorCurrencySymbol () {
      return this.getCurrencySymbol(this.selectedKpiSalesMetrics.paymentMetricsAggregatedDto.currency);
    }
  },
  methods: {
    filterMetricsOnSelectedRealm (selectedRealm) {
      this.selectedRealm = selectedRealm;
      this.selectedKpiSalesMetrics = this.kpiSalesMetrics?.filter(kpiSalesMetric => kpiSalesMetric.realm === selectedRealm)[0];
    },
    async getKpiSalesMetrics () {
      this.loading = true;
      try {
        const { data } = await backofficeService.getBackofficeMetrics(this.startDate, this.stopDate);
        this.kpiSalesMetrics = data;
        this.clientsCount = this.kpiSalesMetrics.filter(kpiSalesMetric => kpiSalesMetric.realm !== 'demo').length;
        this.filterMetricsOnSelectedRealm(this.selectedRealm);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    startDate () {
      this.getKpiSalesMetrics();
    },
    stopDate () {
      this.getKpiSalesMetrics();
    }
  }
};
</script>

<style scoped>
  .realm-selector {
    padding-right: 15px;
    padding-left: 20px;
  }

  .realm-selector-not-xs {
    max-width: 350px;
    min-width: 200px;
  }
</style>
