<template>
  <v-menu
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="selectedMonth"
        :label="$t('MONTH_SELECTION')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedMonth"
      type="month"
      no-title
      scrollable
      :max="currentMonth"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';

export default {
  mixins: [dateMixin],
  data () {
    return {
      currentMonth: undefined,
      selectedMonth: undefined
    };
  },
  beforeMount () {
    this.currentMonth = this.getCurrentDate(true);
    this.selectedMonth = this.getCurrentDate(true);
  },
  watch: {
    selectedMonth () {
      this.$emit('select-month', this.selectedMonth);
    }
  }
};
</script>
