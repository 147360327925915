<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" md="3">
        <RealmSelector
          @select-realm="setSelectedRealm"
          :class="{
            'realm-selector': true,
            'realm-selector-not-xs': !$vuetify.breakpoint.xs
          }"
        ></RealmSelector>
      </v-col>
      <v-col cols="6" md="3">
        <ClientAccountSelector
          v-if="selectedRealm"
          :realm="selectedRealm"
          @select-account="setSelectedAccount"
          :class="{
            'client-account-selector': true,
            'client-account-selector-not-xs': !$vuetify.breakpoint.xs
          }"
        ></ClientAccountSelector>
      </v-col>
    </v-row>
    <v-card max-width="500px">
      <v-card-title>{{ $t('FORM_TITLE') }}</v-card-title>
      <v-card-text>{{ $t('SELECTION_LABEL') }}</v-card-text>
      <v-form>
        <v-autocomplete
          v-model="selectedType"
          :items="Object.values(fileTypeEnumeration)"
          :label="$t('FILE_TYPE')"
          hide-details
          class="px-15"
        >
          <template v-slot:item="{ item }">
            <span> {{ $t(item) }} </span>
          </template>

          <template v-slot:selection="{ item }">
            <span> {{ $t(item) }} </span>
          </template>
        </v-autocomplete>
        <v-file-input
          v-model="selectedFile"
          :placeholder="$t('FILE_INPUT_CSV')"
          accept=".csv"
          class="px-5"
          required
          :error-messages="$t(errorMessageFileInput)"
        >
        </v-file-input>
         <v-text-field
         v-model="hash"
         label="SHA-256"
         class="px-15"
         required
         :error-messages="$t(hashRules)">
        </v-text-field>
        <v-radio-group v-model="isOneOff" row class="px-15">
          <v-radio :label="$t(recurrentPayment)">
          </v-radio>
          <v-radio :label="$t(oneOffPayment)">
          </v-radio>
        </v-radio-group>
        <v-card-actions>
          <v-row justify="space-around">
            <v-btn @click="cancel" color="error" class="my-4">
              {{ $t('ACTION_ERASE') }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="primary"
              class="my-4"
              @click="validate"
            >
              {{ $t('ACTION_CONFIRM') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import RealmSelector from '@/components/backoffice/RealmSelector';
import ClientAccountSelector from '@/components/backoffice/ClientAccountSelector.vue';
import eventMixin from '@/mixins/eventMixin';
import backofficeService from '@/services/backofficeService';

export default {
  mixins: [eventMixin],
  components: {
    RealmSelector,
    ClientAccountSelector
  },
  data () {
    return {
      hash: undefined,
      isOneOff: 0,
      loading: false,
      oneOffPayment: 'ONE-OFF',
      paymentContext: 'recurrent',
      recurrentPayment: 'RECURRENT',
      selectedRealm: undefined,
      selectedAccount: undefined,
      selectedType: undefined,
      selectedFile: undefined
    };
  },
  methods: {
    cancel () {
      this.selectedType = undefined;
      this.selectedFile = undefined;
      this.isOneOff = 0;
      this.hash = undefined;
    },
    setSelectedRealm (selectedRealm) {
      this.selectedRealm = selectedRealm;
    },
    setSelectedAccount (selectedAccount) {
      this.selectedAccount = selectedAccount;
    },
    async validate () {
      this.loading = true;
      if (this.selectedType === 'INVOICE_CREATION') {
        try {
          await backofficeService.loadCSVFile(
            this.paymentContext,
            this.hash,
            this.selectedRealm,
            this.selectedFile
          );
          this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_FILE_UPLOADED'));
        } catch (err) {
          this.handleError(err);
        }
      }
      this.loading = false;
      this.selectedFile = undefined;
      this.isOneOff = 0;
      this.hash = undefined;
    }
  },
  computed: {
    valid () {
      return (
        this.selectedRealm &&
        // this.selectedAccount &&    will be needed later
        this.selectedType &&
        this.selectedFile &&
        this.hash &&
        !this.errorMessageFileInput &&
        !this.hashRules
      );
    },
    errorMessageFileInput () {
      if (!this.selectedFile) {
        return this.selectedFile;
      }
      return !this.selectedFile.name.includes('.csv') ? 'ERR_FILE' : undefined;
    },
    hashRules () {
      const pattern = /^[a-f0-9]{64}$/gi;
      if (!this.hash) {
        return this.hash;
      }
      return pattern.test(this.hash) ? undefined : 'INVALID_FORMAT';
    }
  },
  watch: {
    isOneOff () {
      this.paymentContext = this.isOneOff ? 'one-off' : 'recurrent';
    }
  }
};
</script>