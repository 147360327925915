import Service from './Service';

class BackofficeService extends Service {
  getBackofficeMetrics (startDate, stopDate) {
    return this.instance.get('batchapi/public/v2/metrics/aggregated', {
      params: {
        startDate: startDate,
        stopDate: stopDate
      }
    });
  }

  loadCSVFile (paymentContext, hash, realm, selectedFile) {
    const formData = new FormData();
    formData.append('realm', realm);
    formData.append('file', selectedFile);
    return this.instance.post('invoiceapi/public/v2/backoffice-invoice', formData, { headers: { 'Payment-Context': paymentContext, hash: hash } });
  }

  updateLogo (realm, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.instance.post('userapi/public/v2/backoffice-user/logo', formData, {
      params: {
        realm: realm
      }
    });
  }

  updateFavicon (realm, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.instance.post('userapi/public/v2/backoffice-user/favicon', formData, {
      params: {
        realm: realm
      }
    });
  }

  updatePrimaryColor (creditorId, color) {
    return this.instance.post('userapi/public/v2/creditor-setting/', null, {
      params: {
        creditorId: creditorId,
        settingKey: 'PRIMARY_COLOR',
        value: color
      }
    });
  }

  updateSecondaryColor (creditorId, color) {
    return this.instance.post('userapi/public/v2/creditor-setting/', null, {
      params: {
        creditorId: creditorId,
        settingKey: 'SECONDARY_COLOR',
        value: color
      }
    });
  }
}

export default new BackofficeService();
