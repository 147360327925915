var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('RealmSelector',{class:{'realm-selector': true, 'realm-selector-not-xs': !_vm.$vuetify.breakpoint.xs},on:{"select-realm":_vm.filterMetricsOnSelectedRealm}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('START_DATE'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.currentDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('STOP_DATE'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.stopDate),callback:function ($$v) {_vm.stopDate=$$v},expression:"stopDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.currentDate},model:{value:(_vm.stopDate),callback:function ($$v) {_vm.stopDate=$$v},expression:"stopDate"}})],1)],1),(_vm.showMetrics)?_c('v-col',{staticClass:"text-right pt-sm-8 pr-10",attrs:{"cols":"12","md":"5"}},[_vm._v(" "+_vm._s(_vm.$t('BO_LAST_DATA_DATE', { lastComputationDate: _vm.lastComputationDate }))+" ")]):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.showMetrics)?_c('v-row',_vm._l((_vm.metricCards),function(section){return _c('v-col',{key:section.title,staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-container',[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t(section.title)))]),(_vm.isBoSectionPaymentsOrMatching(section.title) && !_vm.paymentMetrics)?_c('div',{staticClass:"text-center pt-16"},[_c('v-alert',{staticClass:"d-inline-block",attrs:{"type":"info","dense":"","border":"left","outlined":""}},[_vm._v(_vm._s(_vm.$t('BO_INFO_NO_METRICS')))])],1):_c('v-card',{staticClass:"mx-auto pa-5",attrs:{"elevation":"8","color":"#f1f1f1"}},[_c('v-row',{attrs:{"dense":""}},_vm._l((section.cards),function(card){return _c('v-col',{key:card.title,attrs:{"cols":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"text-center",attrs:{"height":"100%"}},'v-card',attrs,false),on),[_c('v-card-title',{staticClass:"justify-center force-break-word"},[_vm._v(_vm._s(_vm.$t(card.title)))]),_c('v-card-text',{staticClass:"black--text"},[_c('strong',[_vm._v(_vm._s(card.value))])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(card.tooltip)))])])],1)}),1),(_vm.isBoSectionInvoices(section.title))?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card',{attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"justify-center force-break-word"},[_vm._v(" "+_vm._s(_vm.$t('PAYMENT_VS_ISSUE'))+" ")]),_c('PaymentKpiBarChart',{staticClass:"px-xl-12 px-lg-0 px-12",attrs:{"kpi-data":_vm.getPaymentVsIssueDate}})],1)],1)],1):_vm._e(),(_vm.isBoSectionInvoices(section.title))?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card',{attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"justify-center force-break-word"},[_vm._v(" "+_vm._s(_vm.$t('PAYMENT_VS_DUE'))+" ")]),_c('PaymentKpiLineChart',{staticClass:"px-xl-12 px-lg-0 px-12",attrs:{"kpi-data":_vm.getPaymentVsDueDate}})],1)],1)],1):_vm._e()],1)],1)],1)}),1):_c('div',{staticClass:"text-center pt-16"},[_c('v-alert',{staticClass:"d-inline-block",attrs:{"type":"info","dense":"","border":"left","outlined":""}},[_vm._v(_vm._s(_vm.$t('BO_INFO_NO_METRICS')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }