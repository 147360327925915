<template>
  <v-autocomplete
    v-model="selectedRealm"
    :items="realmsList"
    dense
    outlined
    :label="$t('CLIENT_SELECTION')"
    return-object
    hide-details
    class="py-3"
  ></v-autocomplete>
</template>

<script>
import contextService from '@/services/contextService';

export default {
  data () {
    return {
      realmsList: [],
      selectedRealm: ''
    };
  },
  async beforeMount () {
    const { data } = await contextService.getRealmsList();
    this.realmsList = data;
    this.selectedRealm = this.realmsList[0];
  },
  watch: {
    selectedRealm () {
      this.$emit('select-realm', this.selectedRealm);
    }
  }
};
</script>