<template>
  <BackOfficeImageUploader
    :maximumImageHeight="maximumLogoHeight"
    :minimumImageHeight="minimumLogoHeight"
    :maximumImageWidth="maximumLogoWidth"
    :minimumImageWidth="minimumLogoWidth"
    :title="$t('LOGO_UPDATE')"
    :actualImageLabel="$t('ACTUAL_LOGO')"
    :eventToEmit="eventToEmit"
    :actualImageUrl="logoUrl"
    @upload-logo="uploadLogo"
  >
  </BackOfficeImageUploader>
</template>

<script>
import globalMixin from '@/mixins/globalMixin';
import backofficeService from '@/services/backofficeService';
import BackOfficeImageUploader from '@/components/backoffice/BackOfficeImageUploader';

export default {
  mixins: [globalMixin],
  components: {
    BackOfficeImageUploader
  },
  data () {
    return {
      maximumLogoHeight: 103,
      minimumLogoHeight: 97,
      maximumLogoWidth: 290,
      minimumLogoWidth: 250,
      eventToEmit: 'upload-logo'
    };
  },
  props: {
    selectedRealm: {
      required: true
    },
    logoUrl: {
      required: true
    }
  },
  methods: {
    async uploadLogo (selectedFile) {
      try {
        await backofficeService.updateLogo(this.selectedRealm, selectedFile);
        this.displaySuccessSnackbar(this.$t('SUCCESSFUL_UPDATE_LOGO'));
      } catch (err) {
        this.handleError(err);
      }
    }
  }
};
</script>