<script>
import { Bar } from 'vue-chartjs';
import 'chartjs-plugin-labels';
import paymentKpiMixin from '@/mixins/paymentKpiMixin';

export default {
  extends: Bar,
  props: ['kpiData'],
  mixins: [paymentKpiMixin],
  mounted () {
    this.renderChart({
      labels: [
        this.$t('PAYMENT_LATE_FIRST_SLICE', this.barFirstSlice),
        this.$t('PAYMENT_LATE_SECOND_SLICE', this.barSecondSlice),
        this.$t('PAYMENT_LATE_THIRD_SLICE', this.barThirdSlice),
        this.$t('PAYMENT_LATE_FOURTH_SLICE', this.barFourthSlice),
        this.$t('PAYMENT_LATE_FIFTH_SLICE', this.barFifthSlice)
      ],
      datasets: [
        {
          backgroundColor: this.barBackgroundColor,
          data: [this.kpiData.firstSlice, this.kpiData.secondSlice, this.kpiData.thirdSlice, this.kpiData.fourthSlice, this.kpiData.fifthSlice]
        }
      ]
    }, {
      legend: false,
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{ scaleLabel: { display: true, labelString: this.$t('PAYMENT_INTERVAL') }, stacked: true, gridLines: { color: '#FFFFFF' } }],
        yAxes: [{ scaleLabel: { display: true, labelString: this.$t('NUMBER_OF_PAYMENTS') }, stacked: true, ticks: { maxTicksLimit: 5, beginAtZero: true } }]
      },
      plugins: {
        labels: {
          render: 'value',
          fontColor: 'white',
          precision: 2
        }
      }
    });
  }
};
</script>