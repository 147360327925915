export default {
  data () {
    return {
      // Bar Chart
      barFirstSlice: [0, 2],
      barSecondSlice: [3, 5],
      barThirdSlice: [6, 10],
      barFourthSlice: [11, 20],
      barFifthSlice: [20],
      barBackgroundColor: ['#4CAF50', '#FF9800', '#F5B041 ', '#F5CBA7', '#EC7063 '],

      // Line Chart
      lineFirstSlice: [-15],
      lineSecondSlice: [-15, -10],
      lineThirdSlice: [-10, -5],
      lineFourthSlice: [-5, -1],
      lineFifthSlice: [0],
      lineSixthSlice: [1, 5],
      lineSeventhSlice: [5, 10],
      lineEighthSlice: [10, 15],
      lineNinthSlice: [15],
      lineBackgroundColor: ['#145A32', '#82E0AA', '#F1C40F', '#F9E79F', '#B9770E', '#AF601A', '#873600', '#A93226', '#641E16']
    };
  },
  methods: {
    paymentVsIssueDate (values) {
      /**
       *[0,2][3,5][6,10][11,20][21,+[
       */
      const data = {
        firstSlice: 0,
        secondSlice: 0,
        thirdSlice: 0,
        fourthSlice: 0,
        fifthSlice: 0
      };
      if (values && values.length) {
        values.forEach(value => {
          if (value <= 2) {
            data.firstSlice++;
          } else if (value >= 3 && value <= 5) {
            data.secondSlice++;
          } else if (value >= 6 && value <= 10) {
            data.thirdSlice++;
          } else if (value >= 11 && value < 20) {
            data.fourthSlice++;
          } else {
            data.fifthSlice++;
          }
        });
      }
      return data;
    },
    paymentVsDueDate (values) {
      /**
       * ]-,-15[, [-15,-10[, [-10,-5[, [-5,-1[, [0,0], [1,5], ]5,10], ]10,15], ]15,+[
       */
      const data = {
        firstSlice: 0,
        secondSlice: 0,
        thirdSlice: 0,
        fourthSlice: 0,
        fifthSlice: 0,
        sixthSlice: 0,
        seventhSlice: 0,
        eighthSlice: 0,
        ninthSlice: 0
      };
      if (values && values.length) {
        values.forEach(value => {
          if (value < -15) {
            data.firstSlice++;
          } else if (value >= -15 && value < -10) {
            data.secondSlice++;
          } else if (value >= -10 && value < -5) {
            data.thirdSlice++;
          } else if (value >= -5 && value <= -1) {
            data.fourthSlice++;
          } else if (value === 0) {
            data.fifthSlice++;
          } else if (value >= 1 && value <= 5) {
            data.sixthSlice++;
          } else if (value > 5 && value <= 10) {
            data.seventhSlice++;
          } else if (value > 10 && value <= 15) {
            data.eighthSlice++;
          } else {
            data.ninthSlice++;
          }
        });
      }
      return data;
    }
  }
};
