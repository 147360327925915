var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('RealmSelector',{class:{
          'realm-selector': true,
          'realm-selector-not-xs': !_vm.$vuetify.breakpoint.xs
        },on:{"select-realm":_vm.setSelectedRealm}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[(_vm.selectedRealm)?_c('ClientAccountSelector',{class:{
          'client-account-selector': true,
          'client-account-selector-not-xs': !_vm.$vuetify.breakpoint.xs
        },attrs:{"realm":_vm.selectedRealm},on:{"select-account":_vm.setSelectedAccount}}):_vm._e()],1)],1),_c('v-card',{attrs:{"max-width":"500px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('FORM_TITLE')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('SELECTION_LABEL')))]),_c('v-form',[_c('v-autocomplete',{staticClass:"px-15",attrs:{"items":Object.values(_vm.fileTypeEnumeration),"label":_vm.$t('FILE_TYPE'),"hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])]}},{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('v-file-input',{staticClass:"px-5",attrs:{"placeholder":_vm.$t('FILE_INPUT_CSV'),"accept":".csv","required":"","error-messages":_vm.$t(_vm.errorMessageFileInput)},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),_c('v-text-field',{staticClass:"px-15",attrs:{"label":"SHA-256","required":"","error-messages":_vm.$t(_vm.hashRules)},model:{value:(_vm.hash),callback:function ($$v) {_vm.hash=$$v},expression:"hash"}}),_c('v-radio-group',{staticClass:"px-15",attrs:{"row":""},model:{value:(_vm.isOneOff),callback:function ($$v) {_vm.isOneOff=$$v},expression:"isOneOff"}},[_c('v-radio',{attrs:{"label":_vm.$t(_vm.recurrentPayment)}}),_c('v-radio',{attrs:{"label":_vm.$t(_vm.oneOffPayment)}})],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-btn',{staticClass:"my-4",attrs:{"color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_ERASE'))+" ")]),_c('v-btn',{staticClass:"my-4",attrs:{"disabled":!_vm.valid,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_CONFIRM'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }