<template>
  <BackOfficeImageUploader
      :maximumImageHeight="maximumFaviconHeight"
      :minimumImageHeight="minimumFaviconHeight"
      :maximumImageWidth="maximumFaviconWidth"
      :minimumImageWidth="minimumFaviconWidth"
      :title="$t('FAVICON_UPDATE')"
      :actualImageLabel="$t('ACTUAL_FAVICON')"
      :eventToEmit="eventToEmit"
      :actualImageUrl="faviconUrl"
      @upload-favicon="uploadFavicon"
  >
  </BackOfficeImageUploader>
</template>

<script>
import globalMixin from '@/mixins/globalMixin';
import BackOfficeImageUploader from '@/components/backoffice/BackOfficeImageUploader';
import backofficeService from '@/services/backofficeService';

export default {
  mixins: [globalMixin],
  components: {
    BackOfficeImageUploader
  },
  data () {
    return {
      maximumFaviconHeight: 32,
      minimumFaviconHeight: 32,
      maximumFaviconWidth: 32,
      minimumFaviconWidth: 32,
      eventToEmit: 'upload-favicon'
    };
  },
  props: {
    selectedRealm: {
      required: true
    },
    faviconUrl: {
      required: true
    }
  },
  methods: {
    async uploadFavicon (selectedFile) {
      try {
        await backofficeService.updateFavicon(this.selectedRealm, selectedFile);
        this.displaySuccessSnackbar(this.$t('SUCCESSFUL_UPDATE_FAVICON'));
      } catch (err) {
        this.handleError(err);
      }
    }
  }
};
</script>