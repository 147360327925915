<template>
  <v-autocomplete
    v-model="selectedAccount"
    :items="accountList"
    dense
    outlined
    :label="$t('ACCOUNT_SELECTION')"
    return-object
    hide-details
    item-text="accountId"
    item-value="accountId"
    class="py-3">
  </v-autocomplete>
</template>

<script>
import userService from '@/services/userService';

export default {
  data () {
    return {
      accountList: [],
      selectedAccount: ''
    };
  },
  props: {
    realm: String
  },
  methods: {
    async getRealmCreditorAccounts () {
      const { data } = await userService.getRealmCreditorsAccounts(this.$props.realm);
      this.accountList = data;
      this.selectedAccount = this.accountList[0];
    }
  },
  async beforeMount () {
    await this.getRealmCreditorAccounts();
  },
  watch: {
    selectedAccount () {
      this.$emit('select-account', this.selectedAccount);
    },
    async realm () {
      await this.getRealmCreditorAccounts();
    }
  }
};
</script>