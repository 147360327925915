<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card class="px-2 pb-5">
        <v-card-title>{{ $t('PRIMARY_COLOR_TITLE') }}</v-card-title>
        <v-row justify="space-around" align="center">
          <v-color-picker
              v-model="primaryColor"
          />
          <v-btn
              color="primary"
              @click="updatePrimaryColor"
          >
            {{ $t('UPDATE') }}
          </v-btn>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="px-2 pb-5">
        <v-card-title>{{ $t('SECONDARY_COLOR_TITLE') }}</v-card-title>
        <v-row justify="space-around" align="center">
          <v-color-picker
              v-model="secondaryColor"
          />
          <v-btn
              color="primary"
              @click="updateSecondaryColor"
          >
            {{ $t('UPDATE') }}
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import backofficeService from '@/services/backofficeService';
import globalMixin from '@/mixins/globalMixin';

export default {
  mixins: [globalMixin],
  data () {
    return {
      primaryColor: null,
      secondaryColor: null
    };
  },
  methods: {
    setActualColors () {
      this.primaryColor = this.$props.mainCreditor.creditorSettings.PRIMARY_COLOR.value;
      this.secondaryColor = this.$props.mainCreditor.creditorSettings.SECONDARY_COLOR.value;
    },
    async updatePrimaryColor () {
      try {
        await backofficeService.updatePrimaryColor(this.mainCreditor.id, this.primaryColor);
        this.displaySuccessSnackbar(this.$t('PRIMARY_SUCCESSFUL_UPDATE'));
      } catch (err) {
        this.handleError(err);
      }
    },
    async updateSecondaryColor () {
      try {
        await backofficeService.updateSecondaryColor(this.mainCreditor.id, this.secondaryColor);
        this.displaySuccessSnackbar(this.$t('SECONDARY_SUCCESSFUL_UPDATE'));
      } catch (err) {
        this.handleError(err);
      }
    }
  },
  props: {
    mainCreditor: {
      required: true
    }
  },
  watch: {
    mainCreditor () {
      this.setActualColors();
    }
  }
};
</script>