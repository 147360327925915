<template>
  <div class="fill-height">
    <v-row
      align="center"
      class="py-1 pt-3">
      <AppAdminMenu/>
    </v-row>
    <v-row class="fill-height">
      <v-col class="mt-5" cols="12">
        <router-view/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppAdminMenu from '@/components/common/menus/AppAdminMenu';

export default {
  components: {
    AppAdminMenu
  }
};
</script>
