<template>
  <div>
    <v-row>
      <v-col cols="6" md="3">
        <RealmSelector
          @select-realm="filterMetricsOnSelectedRealm"
          :class="{'realm-selector': true, 'realm-selector-not-xs': !$vuetify.breakpoint.xs}"
        ></RealmSelector>
      </v-col>
      <v-col cols="6" md="2">
        <MonthSelector
          @select-month="monthSelection"
      ></MonthSelector>
      </v-col>
      <v-col v-if="showMetrics" cols="12" md = "7" class="text-right pt-sm-8 pr-10">
        {{ $t('BO_LAST_DATA_DATE', { lastComputationDate }) }}
      </v-col>
    </v-row>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <v-row v-else-if="showMetrics">
      <v-col v-for="section in metricCards" :key="section.title" cols="6" class="pt-0">
        <v-container>
          <h1 class="text-center">{{ $t(section.title) }}</h1>
          <div v-if="(isBoSectionPayments(section.title) && !paymentMetrics) || (isBoSectionMatching(section.title) && !matchingMetrics)" class="text-center pt-16">
            <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
          </div>
          <v-card v-else
            elevation="8"
            class="mx-auto pa-5"
            color="#f1f1f1">
            <v-row dense>
              <v-col cols="6" md="4" v-for="card in section.cards" :key="card.title">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                        height="100%"
                        class="text-center"
                        v-bind="attrs"
                        v-on="on">
                      <v-card-title class="justify-center force-break-word">{{ $t(card.title) }}</v-card-title>
                      <v-card-text class="black--text">
                        <strong>{{ card.value }}</strong>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span v-if="isBoMAisAccount(card.title)">{{ $t(card.tooltip,
                      { aisAccountMin: selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.aisAccountMin,
                        aisAccountMax: selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.aisAccountMax}) }}
                  </span>
                  <span v-else>{{ $t(card.tooltip) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <div v-else class="text-center pt-16">
      <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
    </div>
  </div>
</template>

<script>
import RealmSelector from '@/components/backoffice/RealmSelector';
import MonthSelector from '@/components/backoffice/MonthSelector';
import backofficeService from '@/services/backofficeService';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [dateMixin, numberMixin],
  components: {
    RealmSelector,
    MonthSelector
  },
  data: () => ({
    customersBillingMetrics: undefined,
    loading: true,
    selectedMonth: undefined,
    selectedCustomerBillingMetrics: undefined,
    selectedRealm: undefined
  }),
  computed: {
    metricCards () {
      return [
        {
          title: 'BO_SECTION_PAYMENTS',
          cards: [
            {
              title: 'BO_M_INVOLVED_DOCUMENTS',
              tooltip: 'BO_M_INVOLVED_DOCUMENTS_TOOLTIP',
              value: this.showInvolvedDocumentsMetrics(this.selectedCustomerBillingMetrics.paymentMetricsAggregatedDto)
            }
          ]
        },
        {
          title: 'BO_SECTION_MATCHING',
          cards: [
            {
              title: 'BO_M_AIS_ACCOUNT',
              tooltip: 'BO_M_AIS_ACCOUNTS_TOOLTIP',
              value: this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.aisAccountAvg.toFixed(2)
            }
          ]
        },
        {
          title: 'BO_SECTION_MESSAGES',
          cards: [
            {
              title: 'BO_SECTION_EMAIL_MESSAGES',
              tooltip: 'BO_SECTION_EMAIL_MESSAGES_TOOLTIP',
              value: this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.emailNotification
            },
            {
              title: 'BO_SECTION_SMS_MESSAGES',
              tooltip: 'BO_SECTION_SMS_MESSAGES_TOOLTIP',
              value: this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.smsNotification
            }
          ]
        }
      ];
    },
    showMetrics () {
      return this.selectedCustomerBillingMetrics && !this.selectedCustomerBillingMetrics.length &&
      this.selectedCustomerBillingMetrics.computationDate;
    },
    isBoMAisAccount: function () {
      return (title) => title === 'BO_M_AIS_ACCOUNT';
    },
    isBoSectionPayments: function () {
      return (title) => title === 'BO_SECTION_PAYMENTS';
    },
    isBoSectionMatching: function () {
      return (title) => title === 'BO_SECTION_MATCHING';
    },
    paymentMetrics () {
      return this.selectedCustomerBillingMetrics.paymentMetricsAggregatedDto &&
      !this.selectedCustomerBillingMetrics.paymentMetricsAggregatedDto.length &&
      this.selectedCustomerBillingMetrics.paymentMetricsAggregatedDto.currency;
    },
    matchingMetrics () {
      return this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto &&
      !this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.length &&
      this.selectedCustomerBillingMetrics.backofficeMetricsAggregatedDto.computationDate;
    },
    lastComputationDate () {
      return this.$options.filters.date(this.selectedCustomerBillingMetrics.computationDate);
    },
    showInvolvedDocumentsMetrics: function () {
      return (metrics) => this.paymentMetrics
        ? metrics.involvedDocumentsCount + ' / ' + metrics.involvedDocumentsAmount.toFixed(2) + ' ' + this.getCurrencySymbol(metrics.currency)
        : metrics;
    }
  },
  methods: {
    filterMetricsOnSelectedRealm (selectedRealm) {
      this.selectedRealm = selectedRealm;
      this.selectedCustomerBillingMetrics = this.customersBillingMetrics?.filter(customersBillingMetrics => customersBillingMetrics.realm === selectedRealm)[0];
    },
    monthSelection (selectedMonth) {
      this.selectedMonth = selectedMonth;
      this.getCustomersBillingMetrics();
    },
    async getCustomersBillingMetrics () {
      this.loading = true;
      const startDate = this.getFirstDayOfMonth(this.selectedMonth);
      const stopDate = this.getLastDayOfMonth(this.selectedMonth);
      try {
        const { data } = await backofficeService.getBackofficeMetrics(startDate, stopDate);
        this.customersBillingMetrics = data;
        this.filterMetricsOnSelectedRealm(this.selectedRealm);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
  .realm-selector {
    padding-right: 15px;
    padding-left: 20px;
  }

  .realm-selector-not-xs {
    max-width: 350px;
    min-width: 200px;
  }
</style>
