<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <RealmSelector
            @select-realm="setSelectedRealm"
            :class="{
              'realm-selector': true,
              'realm-selector-not-xs': !$vuetify.breakpoint.xs
            }"
        ></RealmSelector>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <BackOfficeLogoUploader
            :selectedRealm="selectedRealm"
            :logoUrl="logoUrl"
        ></BackOfficeLogoUploader>
      </v-col>
      <v-col cols="12" md="6">
        <BackOfficeFaviconUploader
            :selectedRealm="selectedRealm"
            :faviconUrl="faviconUrl"
        ></BackOfficeFaviconUploader>
      </v-col>
    </v-row>

    <BackOfficeColorPickers
      v-if="selectedRealm"
      :main-creditor="mainCreditor"
    />

  </v-container>
</template>

<script>
import RealmSelector from '@/components/backoffice/RealmSelector';
import BackOfficeLogoUploader from '@/components/backoffice/BackOfficeLogoUploader';
import environmentService from '@/services/environmentService';
import BackOfficeFaviconUploader from '@/components/backoffice/BackOfficeFaviconUploader';
import BackOfficeColorPickers from '@/components/backoffice/BackOfficeColorPickers';
import userService from '@/services/userService';

export default {
  components: {
    RealmSelector, BackOfficeLogoUploader, BackOfficeFaviconUploader, BackOfficeColorPickers
  },
  data () {
    return {
      selectedRealm: undefined,
      mainCreditor: undefined,
      logoUrl: undefined,
      faviconUrl: undefined
    };
  },
  methods: {
    setSelectedRealm (selectedRealm) {
      this.selectedRealm = selectedRealm;
    }
  },
  watch: {
    async selectedRealm () {
      this.logoUrl = environmentService.getCurrentLogoFromRealm(this.selectedRealm);
      this.faviconUrl = environmentService.getCurrentFaviconFromRealm(this.selectedRealm);
      const { data } = await userService.getRealmMainCreditor(this.selectedRealm);
      this.mainCreditor = data;
    }
  }
};
</script>
