<script>
import { Line } from 'vue-chartjs';
import 'chartjs-plugin-labels';
import paymentKpiMixin from '@/mixins/paymentKpiMixin';

export default {
  extends: Line,
  props: ['kpiData'],
  mixins: [paymentKpiMixin],
  mounted () {
    this.renderChart({
      labels: [
        this.$t('PAYMENT_LINE_LATE_FIRST_SLICE', this.lineFirstSlice),
        this.$t('PAYMENT_LINE_LATE_SECOND_SLICE', this.lineSecondSlice),
        this.$t('PAYMENT_LINE_LATE_THIRD_SLICE', this.lineThirdSlice),
        this.$t('PAYMENT_LINE_LATE_FOURTH_SLICE', this.lineFourthSlice),
        this.$t('PAYMENT_LINE_LATE_FIFTH_SLICE'),
        this.$t('PAYMENT_LINE_LATE_SIXTH_SLICE', this.lineSixthSlice),
        this.$t('PAYMENT_LINE_LATE_SEVENTH_SLICE', this.lineSeventhSlice),
        this.$t('PAYMENT_LINE_LATE_EIGHTH_SLICE', this.lineEighthSlice),
        this.$t('PAYMENT_LINE_LATE_NINTH_SLICE', this.lineNinthSlice)
      ],
      datasets: [
        {
          backgroundColor: this.lineBackgroundColor,
          data: [
            this.kpiData.firstSlice,
            this.kpiData.secondSlice,
            this.kpiData.thirdSlice,
            this.kpiData.fourthSlice,
            this.kpiData.fifthSlice,
            this.kpiData.sixthSlice,
            this.kpiData.seventhSlice,
            this.kpiData.eighthSlice,
            this.kpiData.ninthSlice]
        }
      ]
    }, {
      legend: false,
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{ scaleLabel: { display: true, labelString: this.$t('PAYMENT_INTERVAL') }, stacked: true, gridLines: { color: '#FFFFFF' } }],
        yAxes: [{ scaleLabel: { display: true, labelString: this.$t('NUMBER_OF_PAYMENTS') }, stacked: true, ticks: { maxTicksLimit: 5, beginAtZero: true } }]
      },
      plugins: {
        labels: {
          render: 'value',
          fontColor: 'white',
          precision: 2
        }
      }
    });
  }
};
</script>