<template>
  <v-tabs
    background-color="primary accent-4"
    fixed-tabs
    dark>
    <v-tabs-slider class="d-none"></v-tabs-slider>
    <v-tab
      class="d-none d-sm-flex"
      v-for="(item, i) in menuItems.filter(item => item.menuLabel === $t(menu))[0].subMenu"
      :key="i"
      :to="{ name: item.linkTo }"
      exact
    >
      {{ item.menuLabel }}
    </v-tab>
  </v-tabs>
</template>

<script>
import menuMixin from '@/mixins/menuMixin';

export default {
  mixins: [menuMixin],
  data () {
    return {
      menuArray: ['CUSTOMER_REPORTING', 'CUSTOMER_MANAGEMENT'],
      menu: ''
    };
  },
  methods: {
    selectMenu: function () {
      const route = this.$router.currentRoute.path.substring(12, this.$router.currentRoute.path.length);
      const menuLabel = route.slice(0, route.indexOf('/'));
      this.menu = this.menuArray.find(element => element.includes(menuLabel.toUpperCase()));
    }
  },
  async beforeMount () {
    this.selectMenu();
  },
  watch: {
    '$route' (to, from) {
      this.selectMenu();
    }
  }
};
</script>
