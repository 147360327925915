<template>
  <div>
    <v-row>
      <v-col cols="6" md="3">
        <RealmSelector
          @select-realm="filterMetricsOnSelectedRealm"
          :class="{'realm-selector': true, 'realm-selector-not-xs': !$vuetify.breakpoint.xs}"
        ></RealmSelector>
      </v-col>
      <v-col cols="6" md="2">
        <MonthSelector
          @select-month="monthSelection"
      ></MonthSelector>
      </v-col>
      <v-col v-if="showMetrics" cols="12" md = "7" class="text-right pt-sm-8 pr-10">
        {{ $t('BO_LAST_DATA_DATE', { lastComputationDate }) }}
      </v-col>
    </v-row>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <v-row v-else-if="showMetrics">

      <v-col v-for="section in metricCards" :key="section.title" cols="6" class="pt-0">
        <v-container>
          <h1 class="text-center">{{ $t(section.title) }}</h1>
          <div v-if="isBoSectionPaymentsOrMatching(section.title) && !paymentMetrics" class="text-center pt-16">
            <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
          </div>
          <v-card v-else
            elevation="8"
            class="mx-auto pa-5"
            color="#f1f1f1">
            <v-row dense>
              <v-col cols="6" md="4" v-for="card in section.cards" :key="card.title">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      height="100%"
                      class="text-center"
                      v-bind="attrs"
                      v-on="on">
                      <v-card-title class="justify-center force-break-word">{{ $t(card.title) }}</v-card-title>
                      <v-card-text class="black--text">
                        <strong>{{ card.value }}</strong>
                      </v-card-text>
                    </v-card>
                  </template>
                  <span>{{ $t(card.tooltip) }}</span>
                </v-tooltip>
              </v-col>

            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <div v-else class="text-center pt-16">
      <v-alert class="d-inline-block" type="info" dense border="left" outlined>{{ $t('BO_INFO_NO_METRICS') }}</v-alert>
    </div>
  </div>
</template>

<script>
import RealmSelector from '@/components/backoffice/RealmSelector';
import MonthSelector from '@/components/backoffice/MonthSelector';
import backofficeService from '@/services/backofficeService';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [dateMixin, numberMixin],
  components: {
    RealmSelector,
    MonthSelector
  },
  data: () => ({
    providersBillingMetrics: undefined,
    loading: true,
    selectedMonth: undefined,
    selectedProviderBillingMetrics: undefined,
    selectedRealm: undefined
  }),
  computed: {
    metricCards () {
      return [
        {
          title: 'BO_SECTION_PAYMENTS',
          cards: [
            {
              title: 'BO_M_PAYMENTS_TOTAL',
              tooltip: 'BO_M_PAYMENTS_TOTAL_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.totalPaymentsCount, this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.totalPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_PIS',
              tooltip: 'BO_M_PAYMENTS_BY_PIS_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.pisPaymentsCount, this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.pisPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_DEBIT',
              tooltip: 'BO_M_PAYMENTS_BY_DEBIT_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.sepAddPaymentsCount, this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.sepAddPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_CREDIT_CARD',
              tooltip: 'BO_M_PAYMENTS_BY_CREDIT_CARD_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.ccPaymentsCount, this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.ccPaymentsAmount)
            },
            {
              title: 'BO_M_PAYMENTS_BY_CREDIT_NOTE',
              tooltip: 'BO_M_PAYMENTS_BY_CREDIT_NOTE_TOOLTIP',
              value: this.formatAndShowMetric(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.cnPaymentsCount, this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.cnPaymentsAmount)
            }
          ]
        }
      ];
    },
    showMetrics () {
      return this.selectedProviderBillingMetrics && !this.selectedProviderBillingMetrics.length && this.selectedProviderBillingMetrics.computationDate;
    },
    paymentMetrics () {
      return this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto && !this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.length && this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.currency;
    },
    isBoSectionPaymentsOrMatching: function () {
      return (title) => title === 'BO_SECTION_PAYMENTS' || title === 'BO_SECTION_MATCHING';
    },
    formatAndShowMetric: function () {
      return (count, amount) => this.paymentMetrics ? count + ' / ' + amount.toFixed(2) + this.creditorCurrencySymbol : count + ' / ' + amount;
    },
    lastComputationDate () {
      return this.$options.filters.date(this.selectedProviderBillingMetrics.computationDate);
    },
    creditorCurrencySymbol () {
      return this.getCurrencySymbol(this.selectedProviderBillingMetrics.paymentMetricsAggregatedDto.currency);
    }
  },
  methods: {
    filterMetricsOnSelectedRealm (selectedRealm) {
      this.selectedRealm = selectedRealm;
      this.selectedProviderBillingMetrics = this.providersBillingMetrics?.filter(providersBillingMetrics => providersBillingMetrics.realm === selectedRealm)[0];
    },
    monthSelection (selectedMonth) {
      this.selectedMonth = selectedMonth;
      this.getProvidersBillingMetrics();
    },
    async getProvidersBillingMetrics () {
      this.loading = true;
      const startDate = this.getFirstDayOfMonth(this.selectedMonth);
      const stopDate = this.getLastDayOfMonth(this.selectedMonth);
      try {
        const { data } = await backofficeService.getBackofficeMetrics(startDate, stopDate);
        this.providersBillingMetrics = data;
        this.filterMetricsOnSelectedRealm(this.selectedRealm);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
  .realm-selector {
    padding-right: 15px;
    padding-left: 20px;
  }

  .realm-selector-not-xs {
    max-width: 350px;
    min-width: 200px;
  }
</style>
